.root {
  position: fixed;
  height: 70px;
  background-color: #FFFF;
  width: 100%;
  border-bottom: 1px solid gainsboro;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 30px;

  @media (max-width: 850px) {
    padding: 0px 24px;
  }
}