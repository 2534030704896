* {
  box-sizing: border-box;
}

input,
textarea,
[contenteditable] {
  font: inherit;
  line-height: 1.5;
  overflow: auto;
}

label {
  display: block;
  margin-bottom: 4px;
}

[contenteditable] {
  height: 10rem;
  border: 1px solid;
  resize: both;
}