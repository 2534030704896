.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(174, 176, 179);
  padding: 0.625rem 0.75rem;
  margin: 4px 0px;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 13px !important;

  &:hover {
    background-color: rgb(248, 249, 250);
    color: #364fc7;
  }

  &[data-active] {
    color: #364fc7;
    background-color: rgb(248, 249, 250);
  }
}

.linkIcon {
  color: rgb(220, 222, 224) !important;
  margin-right: 16px;

  &:hover {
    color: #364fc7 !important;
  }

  &[data-active] {
    color: #364fc7 !important;
  }
}