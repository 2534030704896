.titlePage {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: bold !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
.stepProgress {
  flex-direction: column !important;
  gap: 12px !important;
}

.stepBody {
  margin: 0px !important;
}

.contentStepper {
  min-height: 200px;
}

.stepperButton {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -375px;
}